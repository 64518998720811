import React from "react"
import { Link as GaLink, useStaticQuery, graphql } from "gatsby"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import MuiLink from "@material-ui/core/Link"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import CallOutlinedIcon from "@material-ui/icons/CallOutlined"
import Button from "@material-ui/core/Button"

import Layout from "../components/layout"
import Head from "../components/head"
import contactSvg from "../assets/contact.svg"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7, 0, 4),
    },
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  heroImage: {
    width: "100%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  bottomMargin: {
    marginBottom: theme.spacing(6),
  },
  logos: {
    width: "80px",
  },
  iconWrap: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  contactDetail: {
    margin: theme.spacing(5, 0),
  },
}))

const ContactPagePresenter = props => {
  const classes = useStyles()
  const { email, tel } = props.data.site.siteMetadata

  return (
    <Layout>
      <Head title="Contact" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <div className={classes.heroGridContainer}>
              <div>
                <div className={classes.bottomMargin}>
                  <Typography
                    component="h1"
                    variant="h2"
                    align="left"
                    color="textPrimary"
                    gutterBottom
                  >
                    Let's talk
                  </Typography>
                  <Typography
                    variant="h5"
                    align="left"
                    color="textSecondary"
                    paragraph
                  >
                    Get in touch to find out how I can help you. I look forward
                    to speaking to you soon.
                  </Typography>

                  <div className={classes.contactDetail}>
                    <Grid container spacing={2} justify="flex-start">
                      <Grid item>
                        <div className={classes.iconWrap}>
                          <MailOutlineIcon />
                        </div>
                      </Grid>
                      <Grid item>
                        <MuiLink
                          href={`mailto:${email}`}
                          variant="body1"
                          color="inherit"
                        >
                          {email}
                        </MuiLink>
                      </Grid>
                    </Grid>

                    {tel && (
                      <Grid container spacing={2} justify="flex-start">
                        <Grid item>
                          <div className={classes.iconWrap}>
                            <CallOutlinedIcon />
                          </div>
                        </Grid>

                        <Grid item>
                          <MuiLink
                            href={`tel: ${tel}`}
                            variant="body1"
                            color="inherit"
                          >
                            {tel}
                          </MuiLink>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  <Button
                    component={React.forwardRef((props, ref) => (
                      <GaLink innerRef={ref} {...props} />
                    ))}
                    variant="contained"
                    color="primary"
                    to="/free-consultation"
                  >
                    Get In Touch
                  </Button>
                </div>
              </div>

              <div className={classes.heroGridItemImage}>
                <img
                  src={contactSvg}
                  alt="Contact us"
                  className={classes.heroImage}
                />
              </div>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyName
          email
          description
          tel
        }
      }
    }
  `)

  return <ContactPagePresenter data={data} />
}

export default ContactPage
